@use '../../../../styles/variables.scss';

.myt-breadcrumb {
  display: flex;
  align-items: center;

  &__previous,
  &__current {
    color: variables.$text-color;
    font-size: 15px;
  }

  &__previous {
    cursor: pointer;
    text-decoration: none;
    font-weight: 500;

    &:hover {
      text-decoration: underline solid variables.$text-color 2px;
    }
  }

  &__current {
    font-weight: 600;
  }

  &__home-icon {
    cursor: pointer;
  }

  &__next-icon {
    margin: 0 variables.$unit;
  }
}
