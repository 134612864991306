@use '../../../../../styles/variables.scss';

.myt-input {
  &__label {
    font-weight: 600;
    color: variables.$text-neutral;
    font-size: 15px;
    line-height: 20px;
  }

  &__label--error {
    color: variables.$error-800;
  }

  &__required-sign {
  }
}
