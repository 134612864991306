@use '../../../../../styles/variables.scss';

.myt-checkbox {
  --mdc-checkbox-state-layer-size: 30px;
  --mdc-checkbox-selected-focus-icon-color: #{variables.$accent-700};
  --mdc-checkbox-selected-hover-icon-color: #{variables.$accent-700};
  --mdc-checkbox-selected-icon-color: #{variables.$accent-700};
  --mdc-checkbox-selected-pressed-icon-color: #{variables.$accent-700};
  --mdc-checkbox-selected-focus-state-layer-color: #{variables.$accent-700};
  --mdc-checkbox-selected-hover-state-layer-color: #{variables.$accent-700};
  --mdc-checkbox-selected-pressed-state-layer-color: #{variables.$accent-700};
  --mdc-checkbox-unselected-focus-icon-color: #{variables.$neutral-400};
  --mdc-checkbox-unselected-hover-icon-color: #{variables.$neutral-400};

  .mdc-checkbox__background {
    border-radius: variables.$unit;
  }

  .mdc-checkbox__ripple {
    border-radius: variables.$unit * 2;
    opacity: 0.12 !important;
  }

  .mdc-checkbox:hover .mdc-checkbox__ripple {
    background-color: variables.$accent-700 !important;
  }

  .mdc-checkbox:active .mdc-checkbox__native-control ~ .mdc-checkbox__ripple,
  .mdc-checkbox .mdc-checkbox__native-control:focus ~ .mdc-checkbox__ripple {
    background-color: variables.$accent-700;
  }

  .mdc-checkbox__checkmark {
    transform: scale(0.8);
  }

  .mdc-label {
    padding-left: variables.$unit * 1.5;
  }
}
