@use '../../../../styles/variables.scss';

.myt-input {
  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__hint {
    color: variables.$text-neutral;
    font-size: variables.$unit * 3;
  }
}

mat-form-field.mat-mdc-form-field {
  .mat-mdc-text-field-wrapper {
    margin: variables.$unit 0 variables.$unit * 2 0;
    padding: 0 !important;
  }

  .mat-mdc-form-field-flex {
    background: transparent;
    padding: variables.$unit * 3;
    align-items: center;

    .mat-mdc-form-field-infix {
      min-height: 20px;
      padding: 0;
      z-index: 1;
    }
  }

  .mat-mdc-form-field-icon-prefix {
    padding-right: 0;

    .myt-icon {
      padding-right: variables.$unit * 2;
    }
  }

  .mat-mdc-form-field-infix input {
    color: variables.$text-color;
  }

  .mdc-text-field--outlined {
    .mdc-notched-outline__leading {
      border-color: variables.$elevation-3;
      border-radius: variables.$unit * 2;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      background-color: variables.$white;
    }
    .mdc-notched-outline__trailing {
      border-color: variables.$elevation-3;
      border-radius: variables.$unit * 2;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background-color: variables.$white;
    }
  }

  .mat-mdc-form-field-hint,
  .mat-mdc-form-field-error {
    display: flex;
  }

  .mat-mdc-form-field-hint-wrapper,
  .mat-mdc-form-field-error-wrapper {
    padding: 0 variables.$unit * 3;
  }

  // Focus
  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mat-mdc-input-element {
    caret-color: auto;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading {
    border-color: variables.$accent-400;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
    border-color: variables.$accent-400;
  }

  // Disabled
  .mdc-text-field--disabled {
    .mdc-notched-outline__leading {
      background-color: variables.$bg-disabled-input;
      border-width: 0;
    }

    .mdc-notched-outline__trailing {
      background-color: variables.$bg-disabled-input;
      border-width: 0;
    }
  }
}

// Hover
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover
  .mdc-notched-outline
  .mdc-notched-outline__leading {
  border-color: variables.$border-color-hover;
  background-color: variables.$bg-neutral;
  border-width: 1px;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover
  .mdc-notched-outline
  .mdc-notched-outline__trailing {
  border-color: variables.$border-color-hover;
  background-color: variables.$bg-neutral;
  border-width: 1px;
}

// Error
mat-form-field.mat-mdc-form-field.mat-form-field-invalid {
  .mdc-text-field--outlined {
    .mdc-notched-outline__leading {
      border-color: variables.$error-400 !important;
      border-width: 2px !important;
      background-color: variables.$white !important;
    }
    .mdc-notched-outline__trailing {
      border-color: variables.$error-400 !important;
      border-width: 2px !important;
      background-color: variables.$white !important;
    }
  }
  .mat-mdc-form-field-error {
    color: variables.$error-800;
    font-size: variables.$unit * 3;
  }
}

.mat-mdc-paginator {
  mat-form-field.mat-mdc-form-field .mat-mdc-form-field-flex {
    padding: 0;
  }
}
