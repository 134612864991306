@use '../../../../styles/variables.scss';

:host {
  display: flex;
}

.myt-chip.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #{variables.$text-subdued};
  --mdc-chip-elevated-disabled-container-color: #{variables.$bg-disabled-input};
  --mdc-chip-container-height: 24px;
  border: 1px solid;
  border-radius: variables.$unit * 6;
  opacity: 1;

  .mdc-evolution-chip-set__chips {
    max-width: 100%;
  }

  .mdc-evolution-chip__cell--primary,
  .mdc-evolution-chip__action--primary,
  .mat-mdc-chip-action-label {
    overflow: hidden;
  }

  &.mat-mdc-chip-with-avatar {
    --mdc-chip-container-height: 40px;
    --mdc-chip-with-avatar-avatar-size: 32px;
    --mdc-chip-with-icon-icon-size: 32px;
  }

  .mat-mdc-chip-remove .mat-icon {
    font-size: variables.$unit * 5;
    color: variables.$primary-950;
  }

  .myt-chip__remove-button {
    opacity: 1;
    padding-right: variables.$unit;
    padding-left: variables.$unit;
  }

  .myt-chip__text {
    white-space: nowrap;
    user-select: none;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &.mat-mdc-chip:hover .mat-mdc-chip-focus-overlay {
    opacity: 0;
  }

  .myt-chip__icon {
    margin-right: variables.$unit;
    margin-left: -(variables.$unit);
  }

  .mdc-evolution-chip__text-label {
    font-weight: 500;
    font-size: 15px;
  }

  .mat-mdc-chip-action-label {
    display: flex;
    align-items: center;
  }

  &.myt-chip--primary {
    --mdc-chip-elevated-container-color: #{variables.$primary-100};
    --mdc-chip-label-text-color: #{variables.$primary-800};
    border-color: variables.$primary-200;

    &:hover:not(.myt-chip--static) {
      --mdc-chip-elevated-container-color: #{variables.$primary-200};
    }

    .myt-chip__icon .myt-icon {
      color: #{variables.$primary-500};
    }
  }

  &.myt-chip--accent {
    --mdc-chip-elevated-container-color: #{variables.$accent-100};
    --mdc-chip-label-text-color: #{variables.$accent-800};
    border-color: variables.$accent-200;

    &:hover:not(.myt-chip--static) {
      --mdc-chip-elevated-container-color: #{variables.$accent-200};
    }

    .myt-chip__icon .myt-icon {
      color: #{variables.$accent-500};
    }
  }

  &.myt-chip--info {
    --mdc-chip-elevated-container-color: #{variables.$info-100};
    --mdc-chip-label-text-color: #{variables.$info-800};
    border-color: variables.$info-200;

    &:hover:not(.myt-chip--static) {
      --mdc-chip-elevated-container-color: #{variables.$info-200};
    }

    .myt-chip__icon .myt-icon {
      color: #{variables.$info-500};
    }
  }

  &.myt-chip--warning {
    --mdc-chip-elevated-container-color: #{variables.$warn-100};
    --mdc-chip-label-text-color: #{variables.$warn-800};
    border-color: variables.$warn-200;

    &:hover:not(.myt-chip--static) {
      --mdc-chip-elevated-container-color: #{variables.$warn-200};
    }

    .myt-chip__icon .myt-icon {
      color: #{variables.$warn-500};
    }
  }

  &.myt-chip--error {
    --mdc-chip-elevated-container-color: #{variables.$error-100};
    --mdc-chip-label-text-color: #{variables.$error-800};
    border-color: variables.$error-200;

    &:hover:not(.myt-chip--static) {
      --mdc-chip-elevated-container-color: #{variables.$error-200};
    }

    .myt-chip__icon .myt-icon {
      color: #{variables.$error-500};
    }
  }

  &.myt-chip--neutral {
    --mdc-chip-elevated-container-color: #{variables.$neutral-100};
    --mdc-chip-label-text-color: #{variables.$neutral-800};
    border-color: variables.$neutral-200;

    &:hover:not(.myt-chip--static) {
      --mdc-chip-elevated-container-color: #{variables.$neutral-200};
    }

    .myt-chip__icon .myt-icon {
      color: #{variables.$neutral-500};
    }
  }

  &.myt-chip--badge {
    --mdc-chip-elevated-container-color: #{variables.$primary-950};
    --mdc-chip-label-text-color: #{variables.$white};
    border-color: variables.$primary-950;
  }

  &.mat-mdc-chip-disabled {
    border-color: #{variables.$bg-disabled-input};

    .myt-chip__icon .myt-icon {
      color: #{variables.$text-subdued};
    }
  }
}
