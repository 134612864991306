@use '../variables' as vars;

.mdc-snackbar {
  justify-content: flex-start;
  width: 600px;
  min-height: 44px;
  margin: 0 0 vars.$unit * 15 vars.$unit * 15 !important;
  border: solid;
  border-radius: 10px;
  --mdc-snackbar-container-shape: 9px;

  .mdc-snackbar__surface {
    width: 100%;
    padding-right: 0;
  }

  .mdc-snackbar__label {
    padding: vars.$unit 0;
  }

  &.bg-primary {
    --mdc-snackbar-container-color: #{vars.$primary-200};
    --mdc-snackbar-supporting-text-color: #{vars.$primary-700};
    border-color: vars.$primary-400;
  }

  &.bg-error {
    --mdc-snackbar-container-color: #{vars.$error-200};
    --mdc-snackbar-supporting-text-color: #{vars.$error-700};
    border-color: vars.$error-400;
  }

  &.bg-warning {
    --mdc-snackbar-container-color: #{vars.$warn-200};
    --mdc-snackbar-supporting-text-color: #{vars.$warn-700};
    border-color: vars.$warn-400;
  }

  &.bg-info {
    --mdc-snackbar-container-color: #{vars.$accent-200};
    --mdc-snackbar-supporting-text-color: #{vars.$accent-700};
    border-color: vars.$accent-400;
  }
}
