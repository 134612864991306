@use '../../../../../styles/variables.scss';

.myt-select {
  &__suffix {
    cursor: pointer;

    &--disabled {
      color: variables.$text-subdued;
      cursor: auto;
    }
  }

  &__option-image {
    width: 32px;
    height: 32px;
    margin-right: variables.$unit * 3;
  }

  &__select-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__selected {
    background-color: variables.$bg-neutral;

    .myt-select__select-text {
      color: variables.$accent-800;
      font-weight: 500;
    }
  }

  .mat-mdc-form-field-flex {
    cursor: pointer;
  }
}

.mat-mdc-select {
  .mat-mdc-select-arrow {
    display: none;
  }
}

.cdk-overlay-pane {
  .mat-pseudo-checkbox {
    border-radius: variables.$unit;
    width: 20px;
    height: 20px;
    border-width: 1px;
    border-color: variables.$elevation-3;
  }

  .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full {
    background-color: variables.$accent;
  }

  .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after {
    color: variables.$accent-800;
  }

  .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
    color: variables.$accent-800;
    font-weight: 500;
  }

  .mdc-menu-surface.mat-mdc-select-panel {
    margin-top: variables.$unit * 2;
    border-radius: variables.$unit * 2 !important;
    max-height: 200px;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.14);
    padding: 0;
    border: 1px solid variables.$elevation-2;

    .mat-mdc-option {
      min-height: 20px;
      padding: variables.$unit * 3 variables.$unit * 4;
      border-bottom: 1px solid variables.$elevation-1;
    }

    .mdc-list-item__primary-text {
      line-height: 20px;
      display: flex;
      width: 100%;
      align-items: center;
      color: variables.$text-color;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .mat-mdc-option.mdc-list-item--disabled {
    background-color: variables.$bg-disabled-input;

    .mdc-list-item__primary-text {
      color: variables.$text-subdued;
      opacity: 1;
    }
  }
}
