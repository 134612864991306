@use '../../../../styles/variables.scss';

$filters-width: 301px;

:host {
  display: block;
  flex-grow: 1;
  overflow-y: auto;
  border-radius: variables.$unit * 2;
  border: 1px solid variables.$elevation-2;
  position: relative;
}

.myt-table {
  &.mat-mdc-table {
    background: variables.$white;

    .mdc-data-table__header-cell {
      padding: variables.$unit * 3 variables.$unit * 4;
      color: variables.$text-neutral;
      font-size: 15px;
    }

    .mdc-data-table__header-row {
      height: 10.5 * variables.$unit;
    }

    .mdc-data-table__row {
      height: 13 * variables.$unit;
    }

    .mdc-data-table__cell {
      padding: variables.$unit * 4;
      color: variables.$text-color;
      font-size: 15px;
      overflow: hidden;
      max-width: 150px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .multiline.mdc-data-table__cell {
      white-space: unset;
    }

    .mat-mdc-option {
      padding: 0.75rem 0.75rem;
    }

    .mdc-data-table__row:last-child > .mdc-data-table__cell {
      border-bottom: 1px solid variables.$border-color;
    }
  }

  &__wrapper {
    display: flex;
    background: variables.$white;

    &--empty {
      min-height: 650px;
      height: 100%;
    }
  }

  &__loading {
    opacity: 0.5;
  }

  &__header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    color: variables.$text-color;
    font-size: variables.$unit * 5;
    height: variables.$unit * 12;
    align-items: center;
  }

  &__header-title {
    margin-right: variables.$unit * 2;
  }

  &__title-wrapper {
    display: flex;
    align-items: center;
  }

  &__status-error {
    display: flex;
    align-items: center;
    padding-top: variables.$unit * 1.5;
  }

  &__status-error-text {
    font-size: variables.$unit * 3;
    font-weight: 600;
    line-height: 1.33;
    margin-right: variables.$unit;
  }

  &__row--clickable {
    &:hover {
      cursor: pointer;
      background: variables.$bg-neutral !important;
    }
  }

  &__empty-wrapper,
  &__loading-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    width: 100%;
    height: 100%;
    background: variables.$white;
  }

  &__empty-block {
    padding: variables.$unit * 3 variables.$unit * 4;
    border-radius: variables.$unit * 3;
    background: variables.$bg-neutral;
    font-weight: 600;
    line-height: 20px;
    font-size: 15px;
    color: variables.$text-subdued;
  }

  &__no-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 3 * variables.$unit;

    &-block {
      display: flex;
      width: 64px;
      height: 64px;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: variables.$secondary-color;
    }

    h3.myt-table__no-data-title {
      margin: 3 * variables.$unit 0 8 * variables.$unit;
      text-align: center;
    }

    &-text {
      text-align: center;
      font-size: 3.75 * variables.$unit;
    }
  }

  &__no-data &__loader {
    width: 448px;
    border-radius: variables.$unit * 2;
    --mdc-linear-progress-active-indicator-color: #{variables.$primary-700};
    --mdc-linear-progress-track-color: #{variables.$neutral-200};
    --mdc-linear-progress-active-indicator-height: 8px;
    --mdc-linear-progress-track-height: 8px;
    --mdc-linear-progress-track-shape: 8px;
  }

  &__table-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    justify-content: space-between;

    &--empty {
      position: absolute;
      top: 0;
    }
  }

  &__spinner {
    position: absolute;
    top: 50%;
    left: calc(50% + $filters-width / 2);
    transform: translate(-50%, -50%);
    z-index: 1;

    &--without-filters {
      left: 50%;
    }
  }

  &__filters {
    min-width: 300px;
    width: 300px;
    height: 100%;
    overflow: auto;
    display: block;
    border-right: 1px solid #{variables.$neutral-200};

    & &-title {
      height: 72px;
      border-bottom: 1px solid #{variables.$neutral-200};
      display: flex;
      align-items: center;
      padding: 0 variables.$unit * 4;
      margin: 0;
    }

    & &-subtitle {
      display: flex;
      align-items: center;
      padding: 0 variables.$unit * 3;
      margin: 0;
      border-bottom: 1px solid #{variables.$neutral-200};
      border-top: 1px solid #{variables.$neutral-200};
      font-size: 4 * variables.$unit;
      font-weight: 600;
      height: 56px;
    }
  }

  &__paginator.mat-mdc-paginator {
    border: 0;
    border-radius: 0;
    background-color: variables.$white;
    margin: 0 !important;
    padding: variables.$unit * 3 variables.$unit * 4;
    border-top: 1px solid variables.$neutral-200;

    .mat-mdc-paginator-container {
      justify-content: space-between;
    }

    .mat-mdc-select {
      width: variables.$unit * 10.5;
      height: variables.$unit * 4;
    }

    .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
      padding: 0 0 2px !important;
      min-height: variables.$unit * 4;
    }

    .mat-mdc-paginator-container {
      min-height: variables.$unit * 8;
      padding: 0;
    }

    .mat-mdc-icon-button.mat-mdc-button-base {
      --mdc-icon-button-state-layer-size: 28px;
      --mdc-icon-button-icon-size: 20px;
      padding: 5px;
    }

    .mat-mdc-icon-button .mat-mdc-button-touch-target {
      width: variables.$unit * 5;
      height: variables.$unit * 5;
    }

    .mat-mdc-text-field-wrapper {
      text-align: center;
      padding: 0;
    }

    .mat-mdc-paginator-page-size-select {
      width: variables.$unit * 12;
      font-size: 3.75 * variables.$unit;
      border-radius: variables.$unit * 2;
    }

    .mat-mdc-paginator-page-size {
      align-items: center;
    }

    .mat-mdc-select .mat-mdc-select-value-text {
      font-size: 3.75 * variables.$unit;
      font-weight: 600;
    }

    .mat-mdc-paginator-page-size-label {
      font-size: 3.75 * variables.$unit;
      margin-left: 0;
    }

    .mat-mdc-paginator-range-actions {
      font-size: 3.75 * variables.$unit;
    }
  }
}

.cdk-overlay-pane .mdc-menu-surface.mat-mdc-select-panel .mdc-list-item__primary-text {
  --mat-option-selected-state-label-text-color: #{variables.$accent-800};
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(.mat-mdc-option-multiple) {
  font-weight: 600;
}

.cdk-overlay-pane .mdc-menu-surface.mat-mdc-select-panel .mat-mdc-option {
  padding: variables.$unit * 3;
}

.hidden {
  display: none;
}
