@use 'sass:map';

@font-face {
  font-family: 'Inter';
  src: url(../fonts/Inter/Inter-Bold.ttf) format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Inter';
  src: url(../fonts/Inter/Inter-SemiBold.ttf) format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Inter';
  src: url(../fonts/Inter/Inter-Medium.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Inter';
  src: url(../fonts/Inter/Inter-Light.ttf) format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Inter';
  src: url(../fonts/Inter/Inter-Regular.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'DM_Serif_Display';
  src: url(../fonts/dm-serif-display/dm-serif-display-regular-italic.ttf) format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'GT_Walsheim';
  src: url(../fonts/gt-walsheim/gt-walsheim-light.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'GT_Walsheim';
  src: url(../fonts/gt-walsheim/gt-walsheim-medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}
