@use '../../../../styles/variables' as vars;
@use '../../../../styles/colors/colors-variables' as colors-vars;

.myt-tooltip {
  --mdc-plain-tooltip-supporting-text-color: #{colors-vars.$text-color};
  --mdc-plain-tooltip-container-color: #{colors-vars.$white};
  --mdc-plain-tooltip-supporting-text-font: #{vars.$font-family};
  --mdc-plain-tooltip-supporting-text-size: #{vars.$unit * 3};
  --mdc-plain-tooltip-supporting-text-line-height: 1.33;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-container-shape: 4px;

  .mdc-tooltip__surface {
    max-width: 234px;
    padding: vars.$unit * 2 vars.$unit * 3;
    border: 1px solid colors-vars.$neutral-400;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.14);
  }
}
