@use '../../../../styles/variables.scss';

.myt-button {
  background-color: var(--myt-button-background-color);

  &__icon--right {
    margin-left: 3 * variables.$unit;
  }

  &__icon--left {
    margin-right: 3 * variables.$unit;
  }

  &.mdc-button.mat-unthemed {
    --mdc-filled-button-container-color: #{variables.$primary-700};
    --mdc-filled-button-label-text-color: #{variables.$white};
    --mdc-filled-button-disabled-container-color: #{variables.$bg-disabled-input};
    --mdc-filled-button-disabled-label-text-color: #{variables.$text-subdued};
    --mdc-outlined-button-outline-color: #{variables.$elevation-3};
    --mdc-outlined-button-label-text-color: #{variables.$text-color};
    --mdc-text-button-label-text-color: #{variables.$text-color};

    &:hover {
      --mdc-filled-button-container-color: #{variables.$primary-500};
    }

    &[mat-flat-button] {
      &:active {
        --mat-mdc-button-ripple-color: #{variables.$primary-900};
      }
    }

    border-radius: variables.$unit * 2;
    padding: variables.$unit * 3 variables.$unit * 4;
    height: 48px;
    font-family: variables.$font-family;

    &.myt-button--lg {
      height: 48px;
      padding: variables.$unit * 3 variables.$unit * 4;
    }

    &.myt-button--md {
      padding: variables.$unit * 2 variables.$unit * 4;
      height: 40px;
    }

    .mdc-button__label {
      display: flex;
      align-items: center;

      .myt-button__prefix {
        margin-right: variables.$unit * 2;
        margin-left: -(variables.$unit);
      }

      .myt-button__suffix {
        margin-left: variables.$unit * 2;
        margin-right: -(variables.$unit);
      }
    }
  }

  &.myt-button--stroked-white.mdc-button.mat-unthemed {
    --mdc-outlined-button-label-text-color: #{variables.$white};
  }

  &.myt-button--fab-mini.mat-mdc-mini-fab.mat-primary {
    --myt-button-background-color: #{variables.$primary-700};
  }

  &.mat-mdc-icon-button.mat-mdc-button-base {
    padding: variables.$unit * 3;
    height: 48px;

    &.myt-button--lg {
      height: 48px;
      padding: variables.$unit * 3;
    }

    &.myt-button--md {
      padding: variables.$unit * 2;
      height: 36px;
      width: 36px;
    }
  }

  &.shadow-none {
    --mdc-fab-container-elevation-shadow: none;
    --mdc-fab-hover-container-elevation-shadow: none;
    --mdc-fab-focus-container-elevation-shadow: none;
  }
}

.myt-button-full-width {
  width: 100%;
}
