@use '../../../../../styles/variables.scss';

.myt-radio-group {
  display: flex;
  flex-direction: column;
  margin: variables.$unit 0 0;
  align-items: flex-start;

  &__option {
    margin-bottom: variables.$unit * 2;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .mat-mdc-radio-button .mdc-radio {
    --mdc-radio-state-layer-size: 30px;
  }

  .mat-mdc-radio-button .mdc-radio:hover .mdc-radio__native-control:not([disabled]):not(:focus) ~ .mdc-radio__background::before {
    background-color: variables.$accent-700;
    opacity: 0.12;
  }

  .mat-mdc-radio-button
    .mdc-radio:hover
    .mdc-radio__native-control:enabled:not(:checked)
    + .mdc-radio__background
    .mdc-radio__outer-circle {
    border-color: variables.$elevation-3;
  }

  .mat-mdc-radio-button.mat-accent {
    --mdc-radio-selected-focus-icon-color: #{variables.$accent-700};
    --mdc-radio-selected-hover-icon-color: #{variables.$accent-700};
    --mdc-radio-selected-icon-color: #{variables.$accent-700};
    --mdc-radio-selected-pressed-icon-color: #{variables.$accent-700};
  }

  .mdc-label {
    padding-left: variables.$unit * 1.75;
  }

  .icon-radio {
    display: flex;
    align-items: center;
    min-height: 70px;
    width: 100%;
    border: 2px solid variables.$neutral-200;
    border-radius: variables.$unit * 2;
    margin: variables.$unit * 3 0;
    padding: variables.$unit * 3;
    box-sizing: border-box;
    transition: background-color 300ms;

    mat-form-field.mat-mdc-form-field {
      flex-direction: row-reverse;
    }

    &__icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: variables.$neutral-100;
      border-radius: variables.$unit * 2;
      padding: variables.$unit * 2;
      margin: variables.$unit * 2;
      height: 25px;
      transition: background-color 300ms;
    }

    &__control-wrapper {
      width: 100%;

      .title {
        font-weight: 700;
      }
    }

    .mdc-form-field {
      flex-direction: row-reverse;
      justify-content: space-between;
      width: 100%;
    }
  }

  .active-icon-radio {
    border-color: variables.$accent-700;
    .icon-radio__icon-wrapper {
      background-color: variables.$accent-700;
    }
  }
}
