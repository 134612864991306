@use '../../../../styles/variables.scss';

.myt-icon {
  height: 48px;
  width: 48px;
  display: flex;

  .mat-icon {
    height: 100%;
    width: 100%;
    font-size: 48px;
  }
}

.myt-icon--xs {
  height: 20px;
  width: 20px;

  .mat-icon {
    font-size: variables.$unit * 5;
  }
}

.myt-icon--sm {
  height: 24px;
  width: 24px;
  min-height: 24px;
  min-width: 24px;

  .mat-icon {
    font-size: 24px;
  }
}

.myt-icon--md-sm {
  height: 40px;
  width: 40px;

  .mat-icon {
    font-size: 40px;
  }
}

.myt-icon--md {
  height: 50px;
  width: 50px;

  .mat-icon {
    font-size: 50px;
  }
}

.myt-icon--lg {
  height: 56px;
  width: 56px;

  .mat-icon {
    font-size: 56px;
  }
}
