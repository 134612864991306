@use '@angular/material' as mat;
@import 'reset';
@import 'variables';
@import 'typography/typography';
@import 'colors/palette';
@tailwind utilities;

@include mat.typography-hierarchy($myT-desktop-typography);

$myt-theme: mat.define-light-theme(
  (
    color: (
      primary: $myt-primary,
      accent: $myt-accent,
      warn: $myt-error,
    ),
    density: 0,
    typography: $myT-desktop-typography,
  )
);

.cdk-overlay-backdrop.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.5);
}

@import './utilities/index.scss';
@import '../components/src/src/breadcrumb/breadcrumb.component.scss';
@import '../components/src/src/button/button.component';
@import '../components/src/src/chip/chip.component';
@import '../components/src/src/dialog/dialog.component';
@import '../components/src/src/form-controls/inputs';
@import '../components/src/src/form-controls/checkbox/checkbox.component';
@import '../components/src/src/form-controls/datepicker/datepicker.component.scss';
@import '../components/src/src/form-controls/input/input.component.scss';
@import '../components/src/src/form-controls/label/label.component.scss';
@import '../components/src/src/form-controls/select/select.component';
@import '../components/src/src/form-controls/radio-button/radio-button.component';
@import '../components/src/src/form-controls/autocomplete/autocomplete.component.scss';
@import '../components/src/src/icon/icon.component';
@import '../components/src/src/table/table.component';
@import '../components/src/src/tabs/tabs.component';
@import '../components/src/src/link/link.scss';
@import '../components/src/src/tooltip/tooltip.directive';
@import './components/menu/menu';
@import './components/snackbar';

@include mat.core();

// Emit theme-dependent styles for common features used across multiple components.
@include mat.all-component-themes($myt-theme);
